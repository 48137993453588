$scan-mask-border: 20%;
$primary-color: white;

html {
  height: 100%;
}
body {
  font-family: 'Open Sans', sans-serif !important;
  color: #272c33;
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

.control {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
}

.viewer-container {
  position: relative;
  width: 100vw;
  //height: 100vh;
  height: 100%;
  max-height: -webkit-fill-available;
  background: #eee;
  overflow: hidden;

  .anchor-control {
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 10;
    svg {
      cursor: pointer;
      color: #fff;
      background-color: #428bca;
      border: solid 2px #357ebd;

      &.disabled {
	color: white;
	border-color: white;
	background: transparent;
      }
    }
    @media (min-aspect-ratio: "1/1") {
      //left: 3vh;
      //top: 7vw;
      width: 12vh;
      height: 12vh;
    }
    @media (max-aspect-ratio: "1/1") {
      //left: 3vw;
      //top: 7vh;
      width: 12vw;
      height: 12vw;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  .capture-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10vh;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 99;
    background: transparent;

    .image-container {
      border: solid 2px white;
      position: relative;
      width: 70%;
      height: 70%;

      img.preview-photo {
	width: 100%;
	height: 100%;
      }

      .close-button {
	position: absolute;
	right: 0%;
	top: 0%;
	border: solid 2px white;
	border-right: none;
	border-top: none;
	padding: 2%;
	color: white;
	background: transparent;
      }
    }

    .preview-control {
      width: 80%;
      display: flex;
      flex-direction: row;
      button {
	width: 100%;
	padding: 2%;
	cursor: pointer;
	background: transparent;
	border: solid 2px white;
	color: white;
	@media (min-aspect-ratio: "1/1") {
	  font-size: 5vh;
	}
	@media (max-aspect-ratio: "1/1") {
	  font-size: 5vw;
	}
      }
    }
  }
}

.poweredby-overlay.overlay {
  bottom: auto;
  color: #6699b2;
  opacity: 0.8;
  flex-direction: row;
  justify-content: left;
  @media (min-aspect-ratio: "1/1") {
    top: 1vh;
    &.hasPreview {
      top: 16vh;
    }
    left: 1vh;
  }
  @media (max-aspect-ratio: "1/1") {
    top: 1vw;
    &.hasPreview {
      top: 16vw;
    }
    left: 1vw;
  }

  img {
    @media (min-aspect-ratio: "1/1") {
      height: 12vh;
    }
    @media (max-aspect-ratio: "1/1") {
      height: 12vw;
    }
  }
}

.preview-overlay.overlay {
  top: 0px;
  bottom: auto;

  flex-direction: column;
  background: #6699b2; 
  border-bottom: solid 5px #1c3b5f;
  text-align: center;
  color: white;
  opacity: 0.8;
  line-height: 1.5em;
  z-index: 3;

  a {
    text-decoration: none;
    margin-left: 10px;
    padding: 0px 20px;
    background: #eee;
    border: solid 2px #3f4652;
    color: #1c3b5f;
    border-radius: 10px;
    white-space: nowrap;
  }

  @media (min-aspect-ratio: "1/1") {
    font-size: 3vh;
    padding: 2vh;
    height: 10vh;
  }
  @media (max-aspect-ratio: "1/1") {
    font-size: 3vw;
    padding: 2vw;
    height: 10vw;
  }
}

.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  z-index: 2;

  &.hidden {
    display: none;
  }
}

.loading {
  .loader {
    border: 16px solid #6699b2; // background color
    border-top: 16px solid #1c3b5f; // foreground color
    opacity: 0.8;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;

    @media (min-aspect-ratio: "1/1") {
      width: 15vh;
      height: 15vh;
      border-width: 3vw;
      border-top-width: 3vw;
    }
    @media (max-aspect-ratio: "1/1") {
      width: 15vw;
      height: 15vw;
      border-width: 3vw;
      border-top-width: 3vw;
    }

  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}

.pre-start {
  text-align: center;
  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    width: 50%;
    padding: 30px;
    margin: auto;
    background: #6699b2; 
    color: white;
    opacity: 0.8;

    @media (min-aspect-ratio: "1/1") {
      font-size: 5vh;
    }
    @media (max-aspect-ratio: "1/1") {
      font-size: 5vw;
    }
    button {
      margin-top: 5%;
      padding: 3%;
      background: #eee;
      border: solid 2px #3f4652;
      color: #3f4652;
      border-radius: 10px;
      @media (min-aspect-ratio: "1/1") {
	font-size: 5vh;
      }
      @media (max-aspect-ratio: "1/1") {
	font-size: 5vw;
      }
    }
  }
}

.scanning {
  display: flex;
  justify-content: center;

  &.hide {
    display: none;
  }
  &.unseen {
    opacity: 0;
  }

  .inner {
    position: relative;
    display: flex;
    justify-content: center;
    opacity: 0.8;

    @media (min-aspect-ratio: "1/1") {
      width: 60vh;
      height: 60vh;
    }
    @media (max-aspect-ratio: "1/1") {
      width: 80vw;
      height: 80vw;
    }

    background:
      linear-gradient(to right, $primary-color $scan-mask-border, transparent $scan-mask-border) 0 0,
      linear-gradient(to right, $primary-color $scan-mask-border, transparent $scan-mask-border) 0 100%,
      linear-gradient(to left, $primary-color $scan-mask-border, transparent $scan-mask-border) 100% 0,
      linear-gradient(to left, $primary-color $scan-mask-border, transparent $scan-mask-border) 100% 100%,
      linear-gradient(to bottom, $primary-color $scan-mask-border, transparent $scan-mask-border) 0 0,
      linear-gradient(to bottom, $primary-color $scan-mask-border, transparent $scan-mask-border) 100% 0,
      linear-gradient(to top, $primary-color $scan-mask-border, transparent $scan-mask-border) 0 100%,
      linear-gradient(to top, $primary-color $scan-mask-border, transparent $scan-mask-border) 100% 100%;

    background-repeat: no-repeat;
    //background-size: 10vw 10vw;
    background-size: 20% 20%;

    .launch-guide {
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 100%;
      font-size: 30px;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 2;
      .text {
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
      }
      svg {
	display: flex;
	justify-content: center;
	width: 40%;
	height: 40%;
      }
    }

    .scanline {
      position: absolute;
      width: 100%;
      height: 4%;
      background: $primary-color;
      animation: move 2s linear infinite;
      z-index: 2;

      @keyframes move {
	0%, 100% { top: 0% }
	50% { top: calc(100% - 10px) }
      }
    }

    .inner-swiper {
      width: 80%;
      height: 80%;
      align-self: center;
      .swiper-slide {
	display: flex;
	align-items: center;
	justify-content: center;
	img {
	  max-width: 100%;
	  max-height: 100%;
	  width: 100%;
	  height: 100%;
	  opacity: 0.6;
	  object-fit: contain;
	  position: relative;
	  top: 50%;
	  transform: translateY(-50%);
	}
      }
    }
  }

  .selection {
    position: fixed;
    bottom: 0px;
    right: -50%;
    max-width: 50%;
    background: #d8e6ed;
    transition: 0.5s linear;
    &.show {
      right: 0%;
    }
    z-index: 2;
    .items {
      display: flex;
      padding: 2px;
      flex-wrap: wrap;
      .item {
	width: 12vw;
	height: 12vw;
	text-align: center;
	display: flex;
	justify-content: center;
	img {
	  width: 90%;
	  object-fit: contain;
	}
      }
    }
  }

  .dropdown {
    position: fixed;
    bottom: 10px;
    right: 10px;
    @media (min-aspect-ratio: "1/1") {
      height: 12vh;
      width: 12vh;
    }
    @media (max-aspect-ratio: "1/1") {
      height: 12vw;
      width: 12vw;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.2);
    @media (min-aspect-ratio: "1/1") {
      height: 15vh;
    }
    @media (max-aspect-ratio: "1/1") {
      height: 15vw;
    }
    .swiper-container {
      height: 100%;
    }
    .swiper-slide {
      height: 100%;
      @media (min-aspect-ratio: "1/1") {
	width: 15vh;
      }
      @media (max-aspect-ratio: "1/1") {
	width: 15vw;
      }

      border: solid 0px white;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
	width: 100%;
	height: 100%;
	object-fit: cover;
      }
    }
  }
}
